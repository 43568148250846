
import { Component, Watch, Prop } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import { IProductListRequest, IProduct } from "@/types/product";
import { IPagination, ISelectItem } from "@/types";
import { ICustomerProductListRequest } from "@/types/customer_product";
import { IMakerListRequest } from "@/types/maker";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // product
  @Action("product/adminGetList")
  public getProductList!: (request: IProductListRequest) => Promise<boolean>;

  @Getter("product/list")
  public productList!: IProduct[];

  @Getter("product/pagination")
  public pagination!: IPagination<IProduct[]>;

  @Mutation("product/clear")
  public productClear!: () => void;

  // customer_product
  @Action("customer_product/adminGetList")
  public getCustomerProductList!: (
    request: ICustomerProductListRequest
  ) => Promise<boolean>;

  @Getter("customer_product/selectItem")
  public customerProductList!: ISelectItem[];

  @Mutation("customer_product/clear")
  public customerProductClear!: () => void;

  // maker
  @Action("maker/adminGetList")
  public getMakerList!: (request: IMakerListRequest) => Promise<boolean>;

  @Getter("maker/selectItem")
  public makerList!: ISelectItem[];

  @Mutation("maker/clear")
  public makerClear!: () => void;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getProductList(this.params);
  }

  // prop
  @Prop({ default: 0 })
  public fixed_maker_id!: number;

  // パラメータ定義
  public params: IProductListRequest = {
    search: "",
    sort_column: "",
    sort_direction: "",
    page: 1,
    maker_id: 0,
    customer_product: 0
  };

  //
  // variables
  //
  public headers = [
    { text: "商品名", value: "name", sortable: false },
    { text: "品番", value: "code", sortable: false },
    { text: "顧客品番", value: "customer_pn", sortable: false },
    { text: "顧客名", value: "customer_name", sortable: false },
    { text: "規格等", value: "size", sortable: false },
    { text: "商品分類", value: "division", sortable: false },
    { text: "単位", value: "unit", sortable: false },
    { text: "受注単価", value: "unit_price", sortable: false, align: "end" },
    {
      text: "アラート数量",
      value: "alert_quantity",
      sortable: false,
      align: "end"
    }
  ];

  public sort_column_list = [
    { value: "", text: "選択なし" },
    { value: "name", text: "商品名" },
    { value: "code", text: "品番" }
  ];

  public sort_direction_list = [
    { value: "", text: "選択なし" },
    { value: "asc", text: "昇順" },
    { value: "desc", text: "降順" }
  ];

  //
  // methods
  //
  private async created() {
    this.productClear();
    this.customerProductClear();
    if (!this.fixed_maker_id) {
      this.makerClear();
    }
    this.queryToParams();

    if (this.fixed_maker_id) {
      this.params.maker_id = this.fixed_maker_id;
    }

    await Promise.all([
      this.getProductList(this.params),
      this.getCustomerProductList({ per_page: 0 }),
      this.getMakerList({ per_page: 0 })
    ]);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const query = this.$route.query;
    this.params = {
      search: query.search ? decodeURI(query.search as string) : "",
      page: query.page ? Number(query.page) : 1,
      maker_id: query.maker_id ? Number(query.maker_id) : 0,
      customer_product: query.customer_product
        ? Number(query.customer_product)
        : 0,
      sort_column: query.sort_column
        ? decodeURI(query.sort_column as string)
        : "",
      sort_direction: query.sort_direction
        ? decodeURI(query.sort_direction as string)
        : ""
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.params.page = 1;
    if (this.params.sort_column == "") {
      this.params.sort_direction = "";
    }
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          search: this.params.search,
          sort_column: this.params.sort_column,
          sort_direction: this.params.sort_direction,
          page: this.params.page ? String(this.params.page) : "1",
          maker_id: this.params.maker_id ? String(this.params.maker_id) : "0",
          customer_product: this.params.customer_product
            ? String(this.params.customer_product)
            : "0"
        }
      })
      .catch(() => {});
  }

  public rowClick(product: IProduct) {
    this.$router.push(`/admin/product/${product.id}`);
  }
}
