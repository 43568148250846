
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { IMaker } from "@/types/maker";
import ProductList from "@/components/admin/list/ProductList.vue";

@Component({
  components: { ProductList }
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  @Action("maker/adminGet")
  public getMaker!: (id: number) => void;

  @Getter("maker/single")
  public maker!: IMaker;

  @Mutation("maker/clear")
  public clear!: () => void;

  // fields
  public maker_id = 0;
  public disp = false;

  public async created() {
    this.clear();
    this.maker_id = Number(this.$route.params.maker_id);
    await this.getMaker(this.maker_id);
    this.disp = true;
  }
}
